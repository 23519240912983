// @ts-nocheck
import React from "react"
import "../index.css"

import { Box, Flex, Grid } from "@chakra-ui/react"
import { coreFeaturesData1, coreFeaturesData2 } from "../../utils/descriptions"
import { AiOutlineCheck } from "react-icons/ai"
import { IoIosArrowForward } from "react-icons/io"
import { Trans } from "gatsby-plugin-react-i18next"
import ReactPlayer from "react-player/youtube"
import { Link } from "gatsby"
import { mixPanel } from "../../mixpanel/mixpanel"

const FeatureZero = () => {
  const handleTrackClick = value => {
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq("track", value)
      }
    }
    mixPanel.track(value)
  }
  return (
    <Box
      mx={{ "2xl": "18rem", xl: "10rem", lg: "5rem", base: "1rem" }}
      mt={"32"}
    >
      <Grid
        templateColumns={{ lg: "repeat(2, 1fr)", base: "repeat(1, 1fr)" }}
        gap={{ "2xl": 26, xl: 21, lg: 10, sm: 15, base: 5 }}
      >
        <Box mt={{ xl: "16", lg: "10", base: "4" }}>
          <Box
            fontWeight={700}
            fontSize={{ "2xl": 45, xl: 40, lg: 30, base: 25 }}
          >
            <Trans>Core Features</Trans>
          </Box>
          <Box
            w="100%"
            fontSize={{ xl: 20, base: 18 }}
            mt={{ xl: "5", base: "3" }}
            fontWeight={500}
          >
            <Trans>
              Wela School System comes with everything that you need to run your
              school seamlessly.
            </Trans>
          </Box>
          <Flex
            display={["center", "center", "center", "none", "none"]}
            mt="6"
            justifyContent={"center"}
          >
            <Box>
              <ReactPlayer
                url="https://www.youtube.com/watch?v=P1i4wyuZi_U"
                controls
                volume={1}
                width={"320px"}
                height={"200px"}
                onPlay={() => handleTrackClick("Wela School Systems Video")}
              />
            </Box>
          </Flex>
          <Flex
            justifyContent={"center"}
            mb="8"
            display={["center", "center", "center", "none", "none"]}
          >
            <Link
              to="/features"
              onClick={() => handleTrackClick("Features Tab")}
            >
              <Flex gap={1} alignItems="center" ml="8" mt="2">
                <Box fontSize={14} fontWeight={500}>
                  <Trans>View more</Trans>
                </Box>
                <IoIosArrowForward />
              </Flex>
            </Link>
          </Flex>

          <Box w="100%" mt={{ xl: "5", base: "3" }}>
            <Grid
              templateColumns={"repeat(2, 1fr)"}
              gap={{ "2xl": 25, xl: 20, lg: 10, sm: 15, base: 5 }}
            >
              <Box>
                {coreFeaturesData1.map(data1 => (
                  <Flex key={data1} gap="2" alignItems={"center"} py="1">
                    <AiOutlineCheck />
                    <Box fontSize={15}>
                      <Trans>{data1}</Trans>
                    </Box>
                  </Flex>
                ))}
              </Box>
              <Box>
                {coreFeaturesData2.map(data2 => (
                  <Flex key={data2} gap="2" alignItems={"center"} py="1">
                    <AiOutlineCheck />
                    <Box fontSize={15}>
                      <Trans>{data2}</Trans>
                    </Box>
                  </Flex>
                ))}
              </Box>
            </Grid>
          </Box>
        </Box>
        <Box
          position={"relative"}
          mt="36"
          display={["none", "none", "none", "block", "block"]}
        >
          <ReactPlayer
            url="https://www.youtube.com/watch?v=P1i4wyuZi_U"
            controls
            volume={1}
            width="500px"
            height="300px"
            onPlay={() => handleTrackClick("Wela School Systems Video Played")}
          />
          <Link to="/features" onClick={() => handleTrackClick("Features Tab")}>
            <Flex gap={1} alignItems="center" mt="4">
              <Box fontSize={14} fontWeight={500}>
                <Trans>View more</Trans>
              </Box>
              <IoIosArrowForward />
            </Flex>
          </Link>
        </Box>
      </Grid>
    </Box>
  )
}

export default FeatureZero
